import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import "./controllers"

require("./packs/header")
require("./packs/slider")
require("./packs/tabs")
require("./packs/anchors")




